* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  width: 100%;
  height: 100%;
  font-family: sans-serif;
}

.overflow {
  overflow: hidden;
}

.app-inner {
  margin-bottom: 0px;
}

.app-inner-beauty {
  margin-bottom: 0px;
  background-color: #f3f3f3;
}

p {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  background-color: #e5e5e5;
}
